$banner_image: url('../images/banner.webp');
$about_image: url('../images/about.webp');
$background-image: url('../images/bg.webp');
$comment-background-image: url("../images/comment.webp");
$select_text: #fcfcfc;
$select_text_bg: #262a30;
$text: #000;
$text_alt: #666;
$link: #1e8cdb;
$link_hover: #6ec3f5;
$border: #3c495b3a;
$tag_background: #fffd;
$card_background: #ffffffcc;
$page_background: #f5f5f5;
$info_background: #efefefaa;
$navbar_desktop: #282a2c99;
$navbar_mobile: #16171ae6;
$search_background:#fff;
$search_text:#000;
$search_border:#3273dc;
$search_shadow:0 0 0 .125em rgba(50,115,220,.25);
@import './base.scss';